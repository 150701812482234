import * as React from 'react';

function ArrowLeftIcon(props) {
    return (
        <svg
            width="18"
            height="13"
            viewBox="0 0 18 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6.16356 12.0621L5.6733 12.6068L0 6.30339L5.6733 0L6.16356 0.54473L1.33084 5.91414H18V6.69255H1.33084L6.16356 12.0621Z"
                fill={`${props.fill || '#2e2e2e'}`}
            />
        </svg>
    );
}

export default ArrowLeftIcon;
