import React from 'react';

function CardIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="19"
            fill="none"
            viewBox="0 0 24 19"
            {...props}
        >
            <path
                fill="#000"
                fillRule="evenodd"
                d="M20.8.9H3.2c-.575 0-.952 0-1.24.024-.278.023-.393.062-.46.096a1.1 1.1 0 00-.48.48c-.034.067-.073.182-.096.46C.901 2.248.9 2.625.9 3.2v1.905H22.894v.9H.9v9.462c0 .575 0 .952.024 1.24.023.278.062.393.096.46a1.1 1.1 0 00.48.48c.067.033.182.073.46.095.288.024.665.025 1.24.025h17.6c.575 0 .952-.001 1.24-.025.278-.022.393-.062.46-.095a1.1 1.1 0 00.48-.48c.034-.067.073-.182.096-.46.023-.288.024-.665.024-1.24V3.2c0-.575 0-.952-.024-1.24-.023-.278-.062-.393-.096-.46a1.1 1.1 0 00-.48-.48c-.067-.034-.182-.073-.46-.096C21.752.901 21.375.9 20.8.9zM.218 1.092C0 1.52 0 2.08 0 3.2v12.267c0 1.12 0 1.68.218 2.108a2 2 0 00.874.874c.428.218.988.218 2.108.218h17.6c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874c.218-.428.218-.988.218-2.108V3.2c0-1.12 0-1.68-.218-2.108a2 2 0 00-.874-.874C22.48 0 21.92 0 20.8 0H3.2C2.08 0 1.52 0 1.092.218a2 2 0 00-.874.874zM4.444 9.55h-.45v.9H9.34v-.9H4.444z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default CardIcon;
