import React from 'react';

function SecurityBadge(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            fill="none"
            viewBox="0 0 19 19"
            {...props}
        >
            <path
                fill="#000"
                stroke="#fff"
                strokeWidth="0.1"
                d="M17.098 7.117V4.61c0-.283-.212-.606-.503-.66-1.061-.204-2.106-.45-3.128-.807a18.848 18.848 0 01-1.078-.415l.164.069a18.839 18.839 0 01-2.546-1.31c-.206-.127-.403-.27-.613-.393a.687.687 0 00-.69 0c-.05.03-.096.065-.145.096a20.142 20.142 0 01-1.548.914 18.53 18.53 0 01-1.302.624 19.027 19.027 0 01-3.748 1.136l-.458.088c-.143.027-.27.135-.356.244A.674.674 0 001 4.61v4.531c0 .43-.004.86.04 1.29.109 1.08.408 2.115.91 3.08a8.136 8.136 0 001.882 2.386 8.998 8.998 0 001.838 1.217c.5.255 1.002.507 1.503.762l1.425.72.103.053c.11.052.225.094.349.094a.836.836 0 00.354-.098l.094-.047.8-.405c.065-.034.13-.067.196-.098.321-.163.438-.64.247-.938-.212-.326-.595-.418-.938-.246l-1.098.556h.691c-.471-.24-.945-.478-1.417-.717-.575-.291-1.15-.583-1.728-.873a8.127 8.127 0 01-1.27-.794l.139.107a7.663 7.663 0 01-1.264-1.234c-.4-.521-.73-1.089-.985-1.694l.069.165a7.716 7.716 0 01-.501-1.752c-.102-.769-.067-1.556-.067-2.329V4.614l-.503.66c.861-.167 1.716-.353 2.554-.617a19.694 19.694 0 003.312-1.384 18.572 18.572 0 001.663-.994l-.346.094-.347-.094a19.394 19.394 0 006.443 2.775c.36.083.724.151 1.086.22-.168-.22-.334-.44-.503-.66v2.509c0 .358.315.7.685.685.368-.023.681-.307.681-.69h0z"
            ></path>
            <path
                fill="#000"
                d="M17.54 16.621h-4.024c-.206 0-.421.018-.627-.008.061.008.122.016.183.026a.896.896 0 01-.2-.057l.164.069a.872.872 0 01-.174-.104l.14.108a.753.753 0 01-.126-.126l.107.14a.872.872 0 01-.103-.175c.023.055.047.11.068.165a.891.891 0 01-.056-.2c.007.06.015.121.025.182-.018-.141-.008-.288-.008-.429V13.415c0-.17-.014-.346.008-.516l-.025.182a.89.89 0 01.056-.2c-.023.055-.047.11-.068.165a.87.87 0 01.103-.175 6.59 6.59 0 01-.107.14.761.761 0 01.125-.126c-.047.035-.092.07-.139.108a.872.872 0 01.174-.104c-.054.024-.11.047-.164.069a.896.896 0 01.2-.057c-.061.008-.122.015-.182.025.174-.021.358-.008.532-.008h3.583c.21 0 .43-.017.64.008a5.69 5.69 0 01-.182-.025c.069.012.135.03.2.057l-.164-.069c.062.027.119.063.174.104a6.513 6.513 0 01-.14-.108.761.761 0 01.126.125 7.15 7.15 0 00-.108-.139.87.87 0 01.104.175c-.023-.055-.047-.11-.068-.165a.896.896 0 01.056.2 5.684 5.684 0 00-.025-.182c.018.14.008.288.008.429V16.125c0 .17.014.346-.008.516l.025-.182a.897.897 0 01-.056.2c.023-.055.047-.11.068-.165a.871.871 0 01-.104.174c.036-.047.07-.092.108-.139a.753.753 0 01-.125.126c.047-.036.092-.07.139-.108a.872.872 0 01-.174.104c.054-.024.11-.047.164-.069a.897.897 0 01-.2.057c.061-.008.122-.016.182-.026a.93.93 0 01-.105.008c-.358.006-.703.312-.685.685.015.366.301.691.685.685a1.483 1.483 0 001.262-.726c.137-.236.198-.505.198-.777v-3.48a1.463 1.463 0 00-.564-1.148 1.488 1.488 0 00-.916-.315h-2.556c-.646 0-1.292-.002-1.938 0-.27 0-.542.062-.774.203a1.49 1.49 0 00-.717 1.257c-.002.201 0 .405 0 .606v2.505c0 .3-.025.623.088.907.118.295.29.518.544.708.184.137.403.211.63.243.104.013.198.02.3.02H17.54c.358 0 .7-.316.685-.686-.016-.373-.302-.687-.685-.687z"
            ></path>
            <path
                fill="#000"
                d="M15.27 8.532c-.902 0-1.768.497-2.216 1.28a2.59 2.59 0 00-.34 1.278c-.002.176 0 .353 0 .529v.608c0 .37.313.685.685.685H17.14c.37 0 .685-.313.685-.685v-1.12c0-.408-.096-.79-.27-1.158-.188-.4-.503-.728-.861-.98-.41-.29-.928-.433-1.425-.437-.358-.002-.7.317-.685.685.017.374.301.683.685.685.082 0 .162.006.242.016a5.8 5.8 0 01-.181-.025c.164.023.324.064.479.129a5.758 5.758 0 00-.164-.069c.15.065.29.145.418.245a6.855 6.855 0 01-.139-.108c.128.1.241.214.341.34a6.863 6.863 0 00-.108-.138c.098.129.18.27.245.419a5.727 5.727 0 01-.069-.165c.063.155.106.315.13.48-.008-.06-.016-.122-.026-.182.03.239.016.485.016.726v.657l.685-.685H13.397l.685.685v-.657c0-.24-.014-.488.015-.726-.007.06-.015.121-.025.182.024-.165.065-.325.13-.48-.024.055-.048.11-.07.165.065-.151.146-.29.245-.42a6.52 6.52 0 01-.107.14c.1-.127.213-.24.34-.34l-.139.107c.13-.098.27-.18.42-.245l-.165.069c.154-.063.315-.106.48-.13-.061.008-.122.016-.183.026.08-.01.163-.016.243-.016.358 0 .7-.315.685-.685-.014-.372-.298-.685-.681-.685zM14.584 14.952v.472c0 .358.315.7.685.685.372-.016.685-.302.685-.685v-.472c0-.358-.315-.7-.685-.685-.372.016-.685.3-.685.685z"
            ></path>
        </svg>
    );
}

export default SecurityBadge;
