import styled from 'styled-components';
import Link from 'next/link';
import { useCookie } from 'react-use';
import { useForm } from 'react-hook-form';
import { loginSchema } from 'lib/validationSchemas';
import { useTranslation } from '@hooks';
import { useNotification } from '@context/NotificationContext';
import { useAuth } from '@context/AuthContext';
import { InputWrapper } from '@components/common';
import {
    Checkbox,
    CheckboxContainer,
    Form,
    FormWrapper,
    InputContainer,
} from 'components/styles/AuthStyles';
import { NewButton } from '@components/ui';
import { Caption, Caption2 } from '@components/styles/Typography';

const { yupResolver } = require('@hookform/resolvers/yup');

const LoginForm = () => {
    const { t } = useTranslation();
    const { loginMutation } = useAuth();
    const { open } = useNotification();
    const { mutate } = loginMutation;
    const [basketToken] = useCookie('basket-cookie');
    const [rememberMeToken, updateToken] = useCookie('rememberMe');

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(loginSchema),
    });

    const onSubmit = (data) => {
        mutate(
            {
                username: data.email.toLowerCase(),
                password: data.password,
                basketHash: basketToken,
            },
            {
                onSuccess: (data) => {
                    if (data.success === false) {
                        open({
                            type: 'error',
                            title: t('incorrectInformation'),
                        });
                    }
                },
                onError: () => {
                    open({
                        type: 'error',
                        title: t('incorrectInformation'),
                    });
                },
            },
        );
    };

    return (
        <FormWrapper>
            <Form
                onSubmit={handleSubmit(onSubmit)}
                autoComplete={rememberMeToken ? 'on' : 'off'}
                data-testid="login-form"
            >
                <InputContainer>
                    <InputWrapper
                        name="email"
                        width="100%"
                        placeholder={t('email')}
                        error={errors.email?.message}
                        paddingZero
                    >
                        <input
                            {...register('email')}
                            data-testid="login-email-input"
                        />
                    </InputWrapper>
                </InputContainer>
                <InputContainer>
                    <InputWrapper
                        name="password"
                        width="100%"
                        placeholder={t('password')}
                        error={errors.password?.message}
                        paddingZero
                    >
                        <input
                            type="password"
                            {...register('password')}
                            data-testid="login-password-input"
                        />
                    </InputWrapper>
                </InputContainer>
                <CheckboxContainer marginBottom="35px" width="100%">
                    <div>
                        <Checkbox
                            type="checkbox"
                            name="rememberMe"
                            onChange={(e) => {
                                if (e.target.checked) updateToken(true);
                            }}
                        />
                        <label htmlFor="rememberMe">
                            <Caption2>{t('rememberMe')}</Caption2>
                        </label>
                    </div>
                    <Link href="/forgotPassword" to="/forgotPassword" passHref>
                        <Caption>{t('forgotPassword')}</Caption>
                    </Link>
                </CheckboxContainer>
                <ButtonWrapper>
                    <NewButton
                        size="xlarge"
                        type="primary"
                        content={t('signin')}
                        handleClick={handleSubmit(onSubmit)}
                        testId="login-submit-button"
                    />
                </ButtonWrapper>
            </Form>
        </FormWrapper>
    );
};

const ButtonWrapper = styled.div`
    width: 100%;
`;
export default LoginForm;
