import { useTranslation } from '@hooks';
import { H3, Subtitle3, Text3 } from '@components/styles/Typography';
import {
    AgreementModalWrapper,
    StaticContentArea,
    MarginWrapper,
} from 'components/styles/StaticPagesStyles';

const MembershipAgreement = () => {
    const { locale } = useTranslation();

    return (
        <AgreementModalWrapper>
            {locale === 'tr' ? (
                <StaticContentArea>
                    <MarginWrapper>
                        <H3>ÜYELİK SÖZLEŞMESİ</H3>
                    </MarginWrapper>
                    <MarginWrapper>
                        <Subtitle3>MADDE 1 - SÖZLEŞMENİN TARAFLARI</Subtitle3>
                        <Text3>
                            ÜNVANI: KEİKEİ TEKSTIL VE GIYIM SANAYI TICARET
                            ANONIM ŞIRKETI
                        </Text3>
                        <Text3>
                            ADRES: Dolapdere Cd. Küçükbayır Sk. 119/A Şişli /
                            İstanbul
                        </Text3>
                        <Text3>Bundan sonra “KEİKEİ” olarak anılacaktır.</Text3>
                        <Text3>
                            İşbu sözleşme uyarınca www.keikei.com sitesine üye
                            olan ve site üzerinden satışa arz edilen ürün
                            ve/veya hizmetleri satın alan gerçek kişi ise
                            “Üye”’dir . Taraflar ayrı ayrı “Taraf” birlikte
                            “Taraflar” olarak anılacaktır.{' '}
                        </Text3>
                        <Subtitle3>
                            MADDE 2 – SÖZLEŞME KAPSAMI VE AMACI
                        </Subtitle3>
                        <Text3>
                            İşbu Üyelik Sözleşmesi Üye’nin www.keikei.com alan
                            adlı websitesinde sunulan hizmetlerden
                            yararlanmasına ilişkin koşulların ve tarafların
                            karşılıklı yükümlülüklerinin belirlenmesi için
                            akdedilmiştir. Sözleşmenin Üye tarafından kabul
                            edilmesi ile birlikte Üye, Site’de yer alan
                            ürünlere, hizmetlere, içeriklere, uygulamalara ve
                            üyelere yönelik her türlü beyanı da kabul etmiş
                            olduğunu beyan ve taahhüt eder.
                        </Text3>
                        <Subtitle3>MADDE 3- TANIMLAR</Subtitle3>
                        <Text3>
                            Site : www.keikei.com alan adlı çeşitli hizmetlerin
                            ve içeriklerin sunulduğu internet sitesini ifade
                            etmektedir. Üye : İşbu sözleşme uyarınca
                            www.keikei.com sitesine üye olan ve site üzerinden
                            satışa arz edilen ürün ve/veya hizmetleri satın alan
                            gerçek kişiyi ifade etmektedir. Sözleşme : Üye ile
                            KEİKEİ arasında sitenin kullanımı ve tarafların
                            karşılıklı yükümlülüklerini düzenleyen işbu üyelik
                            sözleşmesini ifade etmektedir. Hesabım Sekmesi :
                            Üye’nin sitede yer alan çeşitli uygulamalardan ve
                            hizmetlerden yararlanabilmesi için kişisel
                            bilgilerini girdiği, üye tarafından belirlenen
                            kullanıcı adı ve şifre ile erişilebilen Üye’ye özel
                            sayfayı ifade eder. Gizlilik ve Çerez Politikası :
                            Site’nin işlevselliğini ve performansını arttırmak
                            yoluyla üyelere sunulan hizmetleri geliştirmek,
                            Üye’nin alışveriş deneyimini kişiselleştirmek,
                            Üye’nin site üzerinde görüntülediği içerikler ve
                            ziyaretlerine ilişkin bilgiler doğrultusunda Üye’nin
                            sonraki kullanımları için tercih ve beğenilerine
                            göre geliştirme ve iyileştirmeler yapmak, sitenin ve
                            Üye’nin hukuki ve ticari güvenliğini sağlamak için
                            kullanılan çerezleri ve bu çerezlerin yönetimini
                            içeren metni ifade eder. Kişisel Verilerin
                            Saklanması ve İmha Politikası : Üye’nin kişisel
                            verilerinin, KEİKEİ tarafından işlenme sürelerinin
                            belirlendiği ve işleme süresi ve/veya işleme amacı
                            ortadan kalkan kişisel verilerin silinmesi, yok
                            edilmesi veya anonim hale getirilmesine ilişkin
                            kriter ve yöntemlerin ortaya konduğu ve Site
                            üzerinden erişilebilecek olan metinleri ifade eder.
                        </Text3>
                        <Subtitle3>
                            MADDE 4- TARAFLARIN HAK VE YÜKÜMLÜLÜKLERİ
                        </Subtitle3>
                        <Subtitle3>
                            4.1. ÜYE’NİN HAK VE YÜKÜMLÜLÜKLERİ
                        </Subtitle3>
                        <Text3>
                            4.1.1. Taraflar arasındaki üyelik ilişkisi üye
                            tarafından üyelik bilgileri ile şifrenin girilmesi
                            ve işbu üyelik sözleşmesinin kabulüne ilişkin
                            kutucuğun işaretlenmesi akabinde satıcı tarafından
                            kayıt işleminin onaylanması ile kurulacaktır. Üye,
                            üyelik işlemlerinin gerçekleştirilmesi sırasında
                            bildirmiş olduğu bilgilerin doğru ve güncel
                            olduğunu, bilgilerin eksik veya yanlış olması
                            nedeniyle doğabilecek tüm zararlardan bizzat sorumlu
                            olduğunu kabul, beyan ve taahhüt eder.
                        </Text3>
                        <Text3>
                            4.1.1. Taraflar arasındaki üyelik ilişkisi üye
                            tarafından üyelik bilgileri ile şifrenin girilmesi
                            ve işbu üyelik sözleşmesinin kabulüne ilişkin
                            kutucuğun işaretlenmesi akabinde satıcı tarafından
                            kayıt işleminin onaylanması ile kurulacaktır. Üye,
                            üyelik işlemlerinin gerçekleştirilmesi sırasında
                            bildirmiş olduğu bilgilerin doğru ve güncel
                            olduğunu, bilgilerin eksik veya yanlış olması
                            nedeniyle doğabilecek tüm zararlardan bizzat sorumlu
                            olduğunu kabul, beyan ve taahhüt eder.
                        </Text3>
                        <Text3>
                            4.1.1. Taraflar arasındaki üyelik ilişkisi üye
                            tarafından üyelik bilgileri ile şifrenin girilmesi
                            ve işbu üyelik sözleşmesinin kabulüne ilişkin
                            kutucuğun işaretlenmesi akabinde satıcı tarafından
                            kayıt işleminin onaylanması ile kurulacaktır. Üye,
                            üyelik işlemlerinin gerçekleştirilmesi sırasında
                            bildirmiş olduğu bilgilerin doğru ve güncel
                            olduğunu, bilgilerin eksik veya yanlış olması
                            nedeniyle doğabilecek tüm zararlardan bizzat sorumlu
                            olduğunu kabul, beyan ve taahhüt eder.
                        </Text3>
                        <Text3>
                            4.1.2. Üye, işbu üyelik sözleşmesinin kurulması ile
                            birlikte KEİKEİ tarafından websitesinde sunulan
                            aydınlatma metnini okuduğunu ve anladığını, açık
                            rızasına dayalı olarak işlenen ve/veya aktarılan
                            kişisel verilerinin işlenmesine ve/veya
                            aktarılmasına ilişkin vermiş olduğu açık rızasını
                            dilediği zaman geri çekme hak ve yetkisine sahip
                            bulunduğu hususunda; 6698 s. Kanun’un 11. maddesiyle
                            tarafına tanınan hakları ve bu hakları ne şekilde
                            kullanabileceği hususunda bilgilendirildiğini kabul
                            beyan ve taahhüt eder.
                        </Text3>
                        <Text3>
                            4.1.3. Üyelik işlemlerinin gerçekleştirilmesi için
                            ihtiyaç duyulan şifre Üye tarafından belirlenecek
                            olup Üye, kişisel profilinin kullanıma imkan veren
                            kullanıcı adı ve şifre dahil olmak üzere tüm
                            bilgilerinin kullanım, yönetim, güvenlik ve
                            gizliliğinden bizzat sorumlu olacaktır. Üye söz
                            konusu kullanıcı adı ve şifresini hiçbir şart ve
                            koşulda üçüncü kişiler ile paylaşmayacağını,
                            kullanıcı hesabını üçüncü kişilere devredemeyeceğini
                            kabul beyan ve taahhüt eder.
                        </Text3>
                        <Text3>
                            4.1.4. Üye kendisine ait kullanıcı adı ve şifre ile
                            gerçekleştirilen işlemlerinin kendisi tarafından
                            gerçekleştirilmiş olduğunu, bu işlemlerden
                            kaynaklanan sorumluluğun kendisine ait olduğunu,
                            kendisine ait hesap üzerinden gerçekleştirilen iş ve
                            işlemleri kendisinin gerçekleştirmediği yönünde
                            herhangi bir def’i ve/veya itiraz ileri
                            süremeyeceğini ve bu def’i ve itirazlara dayanarak
                            yükümlülüklerini yerine getirmekten kaçınamayacağını
                            kabul, beyan ve taahhüt eder.
                        </Text3>
                        <Text3>
                            4.1.5. Üye site üzerinden kendisine sunulan
                            hizmetlerden yararlanırken ve site üzerinde
                            gerçekleştireceği tüm işlemler ve yazışmalarda işbu
                            Sözleşme hükümlerine ve yürürlükteki mevzuata uygun
                            olarak hareket edeceğini, aşağıdaki haller başta
                            olmak üzere siteyi hukuka ve ahlaka aykırı bir
                            şekilde kullanmayacağını kabul, beyan ve taahhüt
                            eder.
                        </Text3>
                        <Text3>
                            • Site’nin herhangi bir kişi adına veri tabanı,
                            kayıt veya rehber yaratmak, kontrol etmek,
                            güncellemek veya değiştirmek amacıyla kullanılması,
                        </Text3>
                        <Text3>
                            • Site’nin bütününün veya bir bölümünün bozma,
                            değiştirme veya ters mühendislik yapma amacıyla
                            kullanılması,
                        </Text3>
                        <Text3>
                            • Yanlış bilgiler veya başka bir kişinin bilgileri
                            kullanılarak işlem yapılması, yanlış veya yanıltıcı
                            ikametgâh adresi, elektronik posta adresi, iletişim,
                            ödeme veya hesap bilgileri de dahil yanlış veya
                            yanıltıcı kişisel veriler kullanmak suretiyle gerçek
                            olmayan Üyelik hesapları oluşturulması ve bu
                            hesapların Üyelik Sözleşmesi`ne veya yürürlükte
                            mevzuata aykırı şekilde kullanılması, başka bir
                            Üye`nin hesabının izinsiz kullanılması, başka
                            birinin yerine geçilerek ya da yanlış bir isimle
                            işlemlere taraf ya da katılımcı olunması;
                        </Text3>
                        <Text3>
                            • Yorum ve puanlama bölümünün; Site’deki yorumları
                            Site’nin dışında yayınlamak gibi amaçlar için
                            kullanılması veya sistemleri manipüle edecek şekilde
                            kullanılma amaçları dışında kullanılması;
                        </Text3>
                        <Text3>
                            • Yorum bölümünde konusu suç teşkil eden, internet
                            sitesindeki kurallara, yürürlükteki mevzuata veya
                            uluslararası anlaşmaların ihlali sonucunu doğuran ya
                            da böyle durumları teşvik eden, yasadışı, tehditkar,
                            reklam ve/veya pazarlama içerikli, rahatsız edici,
                            hakaret ve küfür içeren, aşağılayıcı, küçük
                            düşürücü, kaba, pornografik ya da ahlaka aykırı,
                            toplumca genel kabul görmüş kurallara aykırı,
                            kişilik haklarına aykırı, fikri ve sınai haklara
                            aykırı, haksız rekabet yaratan ve/veya benzer
                            herhangi bir içeriğin Site’de paylaşılması;
                        </Text3>
                        <Text3>
                            • Virüs veya Site’ye, Site’nin veri tabanına, Site
                            üzerinde yer alan herhangi bir içeriğe zarar verici
                            herhangi başka bir teknoloji yayılması
                        </Text3>
                        <Text3>
                            • Site tarafından belirlenmiş olan iletişimler ve
                            teknik sistemler üzerinde makul olmayan veya
                            orantısız derecede büyük yüklemeler yaratacak ya da
                            teknik işleyişe zarar verecek faaliyetlerde
                            bulunulması, KEİKEİ’nin önceden yazılı iznini
                            alınmaksızın Site’nin üzerinde otomatik program,
                            robot, web crawler, örümcek, veri madenciliği (data
                            mining) ve veri taraması (data crawling) gibi
                            `screen scraping` yazılımları kullanılması ve
                            bunlarla sınırlı olmamak üzere diğer yöntemlerle
                            Site’de yer alan herhangi bir içeriğin tamamının
                            veya bir kısmının izinsiz kopyalanarak, yayınlanması
                            veya kullanılması.
                        </Text3>
                        <Text3>
                            • Üye Site’ye zarar verebilecek her türlü program,
                            virüs, yazılım, lisansız ürün, truva atı vb. zararlı
                            kodları içermemesi için gerekli koruyucu yazılımları
                            ve lisanslı ürünleri kullanmak da dâhil olmak üzere
                            gerekli her türlü tedbiri aldığını kabul ve taahhüt
                            eder. Üye ayrıca Hesabım Sayfası`na robot veya
                            otomatik giriş yöntemleriyle girmeyeceğini kabul
                            beyan ve taahhüt eder.
                        </Text3>
                        <Text3>
                            • Üye tarafından, ”Ticari İletişime İlişkin Açık
                            Rıza/Ticari İletişim İzni” kapsamında açık rıza
                            vermesi halinde; yürürlükte bulunan ve/veya
                            yürürlüğe alınacak uygulamalar kapsamında KEİKEİ
                            tarafından kendisine ürün ve hizmet tanıtımları,
                            reklamlar, kampanyalar, avantajlar, anketler ve
                            diğer müşteri memnuniyeti uygulamaları sunulmasına
                            izin verdiğini beyan ve kabul eder.
                        </Text3>
                        <Text3>
                            • Üye’nin Site’yi Sözleşme’deki herhangi bir maddeye
                            aykırı kullanması halinde KEİKEİ, Üye’nin Site’nin
                            tamamını ve/veya bir bölümünü kısmen ve/veya tamamen
                            kullanmasını engelleme hakkına sahiptir
                        </Text3>
                        <Subtitle3>
                            4.2. KEİKEİ’NİN HAK VE YÜKÜMLÜLÜKLERİ
                        </Subtitle3>
                        <Text3>
                            4.2.1 KEİKEİ, herhangi bir gerekçe göstermeksizin
                            tamamen kendi iradesi ve kararı ile üyelik
                            başvurularını reddetme veya üyelik başvuruları için
                            ek şart ve koşullar getirme hakkına haizdir.
                        </Text3>
                        <Text3>
                            4.2.2. KEİKEİ, herhangi bir gerekçe göstermeksizin
                            ve ihbarda bulunmaksızın sitede sunulan hizmetler,
                            işbu Üyelik Sözleşmesi, Site’de yer alan Kişisel
                            Verilerin Saklanması ve İmha Politikası ve Çerez
                            Politikası da dahil her türlü politika ve sözleşmede
                            mevzuattaki hükümlere aykırı olmamak kaydıyla her
                            zaman değişiklik yapma hakkına sahiptir. Yapılacak
                            değişiklikler yayımlanma tarihinden itibaren
                            Taraflar için bağlayıcı nitelik kazanacak, geri
                            kalan hükümler aynen yürürlükte kalarak hüküm ve
                            sonuçlarını doğurmaya devam edecektir.
                        </Text3>
                        <Text3>
                            4.2.3. Site’ye girilmesi, Site’nin ya da Site’deki
                            bilgilerin, verilerin ve programların kullanılması
                            sebebiyle, sözleşmenin ihlali, haksız fiil, ya da
                            başkaca sebeplere binaen, doğabilecek doğrudan ya da
                            dolaylı maddi ve/veya manevi menfi ve/veya müspet
                            hiçbir zarardan KEİKEİ ve Şirket yönetim kurulu
                            üyeleri, yöneticileri, çalışanları ve bu sitede yer
                            alan bilgileri hazırlayan kişiler sorumlu değildir.
                            KEİKEİ, sözleşmenin ihlali, haksız fiil, ihmal veya
                            diğer sebepler neticesinde; işlemin kesintiye
                            uğraması, hata, ihmal, kesinti hususunda herhangi
                            bir sorumluluk kabul etmez.
                        </Text3>
                        <Text3>
                            4.2.4. KEİKEİ, Üye tarafından Üyelik Sözleşmesine,
                            hukuka ve genel ahlak kurallarına aykırı, tehdit
                            edici, yanlış, yanıltıcı, tahrik edici, küçültücü,
                            gizliliği ihlal edici, müstehcen, pornografik,
                            istismar edici, ayrımcılık gözeten, yasadışı şekilde
                            veya ceza gerektiren suç sayılabilecek, herhangi bir
                            tarafın haklarını ihlal eden herhangi bir davranışı
                            teşvik edici veya başka şekilde hukuki sorumluluğa
                            yol açabilecek ya da herhangi bir kanunu ihlal
                            edebilecek veya KEİKEİ tarafından kabulü mümkün
                            olmayan mesajların ve/veya içeriklerin tespit
                            edilmesi halinde bu mesaj, yorum ve içerikleri her
                            zaman erişimden kaldırma hakkına sahiptir.
                        </Text3>
                        <Text3>
                            4.2.5. KEİKEİ, Site’ye ilişkin sistemde oluşabilecek
                            sorunların tespit edilebilmesi ve giderilmesi,
                            internet sitesinin ve sistemin iyileştirilmesi ve
                            geliştirilmesine yönelik olarak siteye erişmek için
                            kullanıcılarca kullanılan internet servis
                            sağlayıcısının adı ve Internet Protokol (IP) adresi,
                            siteye erişilen tarih ve saat, sitede bulunulan
                            sırada erişilen sayfalar ve siteye doğrudan
                            bağlanılmasını sağlayan internet sitesinin internet
                            adresi gibi birtakım bilgileri toplama hakkına
                            sahiptir.
                        </Text3>
                        <Subtitle3>MADDE 5- GİZLİLİK</Subtitle3>
                        <Text3>
                            KEİKEİ, Üye tarafından Site üzerinden kendisine
                            sağlanan kişisel verilerin 6698 sayılı Kişisel
                            Verilerin Korunması Kanunu ve ikincil mevzuata uygun
                            bir şekilde işlenmesinden, güvenliğinin
                            sağlanmasından ve korunmasından sorumlu olacaktır.
                            KEİKEİ, bu kapsamda Üye`nin sağladığı kişisel
                            verileri Aydınlatma Metninde yer alan amaçlarla
                            sınırlı olarak ve Site’de yer alan Kişisel Verilerin
                            Saklanması ve İmha Politikası ve Çerez Politikası`na
                            uygun olarak toplayacağını, işleyeceğini,
                            kullanacağını, saklayacağını ve aktaracağını kabul
                            beyan ve taahhüt eder.
                        </Text3>
                        <Subtitle3>MADDE 6- FİKRİ MÜLKİYET HAKLARI</Subtitle3>
                        <Text3>
                            Site’de yer alan alan adı, ticari marka, logo,
                            tasarım, grafik, görsel ve resimler dahil fakat
                            bunlarla sınırlı olmamak üzere her türlü işitsel,
                            görsel, yazılı materyalin ve içeriğin fikri mülkiyet
                            hakları KEİKEİ’ye ait olup Üye, KEİKEİ’nin yazılı
                            onayı olmaksızın, doğrudan ve/veya dolaylı olarak
                            aynen ya da başka bir şekilde Site’nin içeriği
                            kopyalayamaz, çoğaltamaz, yayınlayamaz, dağıtamaz,
                            iletemez, değiştiremez, ve/veya başka bir şekilde
                            kullanamaz. Aksi takdirde Üye, KEİKEİ’nin
                            uğradığı/uğrayacağı her türlü zarar ile lisans
                            verenler de dahil ancak bunlarla sınırlı olmaksızın,
                            üçüncü kişilerin uğradıkları zararlardan dolayı
                            KEİKEİ`den talep edilen her türlü tazminat tutarını,
                            mahkeme masraflarını ve avukatlık ücreti de dahil
                            ancak bunlarla sınırlı olmamak üzere her türlü
                            zararı KEİKEİ’ye derhal ödemekle sorumlu olacaktır.
                        </Text3>
                        <Subtitle3>MADDE 7- MÜCBİR SEBEPLER</Subtitle3>
                        <Text3>
                            Doğal afet, salgın hastalık, grev, hükümet, kanun ve
                            yasa değişiklikleri, ayaklanma, ambargo, isyan,
                            işgal, savaş, seferberlik, grev, lokavt, iş
                            eylemleri veya boykotlar, siber saldırı, iletişim
                            sorunları, altyapı ve internet arızaları, sisteme
                            ilişkin iyileştirme veya yenileştirme çalışmaları ve
                            bu sebeple meydana gelebilecek arızalar, elektrik
                            kesintisi, yangın, patlama, fırtına, sel, deprem,
                            göç, salgın veya diğer bir doğal felaket, Site’ye
                            erişimi engelleyecek her türlü idari ve/veya yargı
                            kararı veya benzeri işlemler dahil olmak KEİKEİ’nin
                            kontrolü dışında gerçekleşen olaylar KEİKEİ’nin işbu
                            üyelik sözleşmesinden doğan yükümlülüklerini yerine
                            getirmesini engeller veya geciktirirse KEİKEİ
                            yükümlülüklerin yerine getirilmesinden sorumlu
                            tutulmayacak ve bu durum sözleşmenin ihlali olarak
                            değerlendirilmeyecektir.
                        </Text3>
                        <Subtitle3>MADDE 8- SÖZLEŞME’NİN YORUMU</Subtitle3>
                        <Text3>
                            İşbu Sözleşme’nin esaslı unsurları dışındaki
                            herhangi bir maddesinin, alt maddesinin veya
                            hükmünün geçersiz veya hukuken ifa edilemez
                            olduğunun tespit edilmesi işbu Sözleşme’nin diğer
                            madde, alt madde veya hükümlerinin
                            uygulanabilirliğini veya geçerliliğini
                            etkilemeyecektir. Böyle bir durumda, işbu
                            Sözleşme’de her bakımdan, geçersiz veya hukuken ifa
                            edilemez olduğu tespit edilen madde, alt madde veya
                            hükümler Sözleşme metninden çıkarılmış gibi
                            değerlendirilerek yorumlanacak ve ifa edilecektir.
                        </Text3>
                        <Text3>
                            Kişisel Verilerin Saklanması ve İmha Politikası,
                            Çerez Politikası ve Aydınlatma Metni de işbu
                            Sözleşme’nin ayrılmaz bir parçası olarak işbu
                            Sözleşme ile yorumlanacaktır.
                        </Text3>
                        <Subtitle3>
                            MADDE 9- ÜYELİK İPTALİ VE SÖZLEŞMENİN FESHİ
                        </Subtitle3>
                        <Text3>
                            Taraflardan her biri işbu sözleşmeyi tek taraflı
                            olarak her zaman feshedebilir.
                        </Text3>
                        <Text3>
                            Üye’nin işbu sözleşmeden doğan yükümlülüklerinden
                            herhangi birine veya tamamına aykırı hareket etmesi
                            durumunda KEİKEİ, herhangi bir bildirime gerek
                            olmaksızın ve gerekçe göstermeksizin tek taraflı
                            olarak işbu sözleşmeyi feshetme ve Üye’nin üyeliğini
                            iptal etme Üye`nin siteden aldığı, almakta olduğu ya
                            da alacağı hizmetleri kısmen veya tamamen dondurma
                            veya iptal etme hakkına sahiptir.
                        </Text3>
                        <Subtitle3>MADDE 10- MUHTELİF HÜKÜMLER</Subtitle3>
                        <Text3>
                            Delil Sözleşmesi : Üye, işbu Üyelik Sözleşmesi’nden
                            doğabilecek ihtilaflarda ÜYE’nin diğer delil sunma
                            hakları saklı kalmak kaydıyla; KEİKEİ’nin resmi
                            defter ve ticari kayıtları ile KEİKEİ’nin veri
                            tabanında, sunucularında tutulan e-arşiv
                            kayıtlarının, elektronik bilgilerin, elektronik
                            yazışmaların ve bilgisayar kayıtlarının, bağlayıcı
                            ve kesin delil teşkil edeceğini ve bu maddenin 6100
                            sayılı Hukuk Muhakemeleri Kanunu’nun 193. maddesi
                            anlamında delil sözleşmesi niteliğinde olduğunu
                            kabul eder.
                        </Text3>
                        <Text3>
                            Üyelik Sözleşmesi`nin Bütünlüğü ve Bölünebilirliği:
                            İşbu Üyelik Sözleşmesi, konuya ilişkin olarak
                            Taraflar arasındaki anlaşmanın tamamını
                            oluşturmaktadır. İşbu Üyelik Sözleşmesi’nin herhangi
                            bir hükmünün yetkili herhangi bir mahkeme, tahkim
                            heyeti veya idari makam tarafından tümüyle veya
                            kısmen geçersiz veya uygulanamaz olduğu veya makul
                            olmadığına karar verilmesi halinde, söz konusu
                            geçersizlik, uygulanamazlık veya makul olmama
                            ölçüsünde işbu Üyelik Sözleşmesi bölünebilir olarak
                            kabul edilecek ve diğer hükümler tümüyle yürürlükte
                            kalmaya devam edecektir.
                        </Text3>
                        <Text3>
                            Tadil ve Feragat: Taraflar`dan birinin Üyelik
                            Sözleşmesi`nde kendisine verilen herhangi bir hakkı
                            kullanmaması ya da icra etmemesi, söz konusu haktan
                            feragat ettiği anlamına gelmeyecek veya söz konusu
                            hakkın daha sonra kullanılmasını ya da icra
                            edilmesini engellemeyecektir.
                        </Text3>
                        <Text3>
                            Kişisel verileriniz, “bilme gereği” ve “kullanma
                            gereği” ilkelerine uygun olarak ve gerekli veri
                            minimizasyonunu sağlanarak; yukarıda belirtilen
                            amaçların gerçekleştirilmesi doğrultusunda ve bu
                            amaçların yerine getirilmesi ile sınırlı olarak;
                            hissedarlarımızla, iş ortaklarımızla, iştiraklerimiz
                            ve bağlı şirketlerimiz ve hissedarlarıyla,
                            Şirketimizin yurtiçi ve yurtdışındaki hizmet
                            sağlayıcılarıyla, pazarlama/reklam/analiz hizmeti
                            sağlayıcıları, veri tabanı ve sunucu hizmeti
                            sağlayıcıları, site kullanımlarını izleme hizmeti
                            sağlayanlar, e-posta sunucu hizmeti sağlayıcıları,
                            e-fatura ve e-arşiv fatura hizmeti sağlayıcıları,
                            elektronik ileti aracı hizmet sağlayıcıları, kargo
                            ve kurye firmaları, kart basım hizmeti verenler,
                            matbaalar, banka ve elektronik ödeme kuruluşları,
                            hukuki ve mali danışmanlık hizmeti verenler bağımsız
                            denetim hizmeti sağlayıcıları, arşivleme hizmeti
                            verenler, bilişim altyapımızı sağlayan, işleten veya
                            hizmet sunan iş ortaklarımızla ve hizmet
                            sağlayıcılarımızla, özel iş ortaklığımızın olduğu
                            kanunen yetkili kamu kurumları ve özel kişi veya
                            kuruluşlar ile üçüncü kişilere,
                        </Text3>
                        <Subtitle3>
                            MADDE 11- YETKİLİ MAHKEME VE UYGULANACAK HUKUK
                            ANLAŞMAZLIKLARIN ÇÖZÜMÜ
                        </Subtitle3>
                        <Text3>
                            İşbu Üyelik sözleşmesinden kaynaklanan
                            uyuşmazlıklara Türk Hukuku uygulanacak ve işbu
                            sözleşme ile eklerinin uygulanmasından doğabilecek
                            her türlü anlaşmazlığın çözümünde İstanbul
                            (Çağlayan) Mahkemeleri ve İcra Daireleri yetkili
                            olacaktır.
                        </Text3>
                        <Subtitle3>MADDE 12- TEBLİGAT</Subtitle3>
                        <Text3>
                            KEİKEİ tarafından işbu sözleşmede belirtilen adres
                            ile Üye tarafından KEİKEİ’ye bildirilen elektronik
                            posta adresi sözleşme ile ilgili olarak yapılacak
                            her türlü bildirim için tarafların tebligata
                            elverişli adresi kabul edilecek olup belirtilen
                            adreslere yapılan tebligat usulüne uygun yapılmış
                            geçerli bir tebligatın sonuçlarını doğuracaktır.
                        </Text3>
                        <Subtitle3>MADDE 13- YÜRÜRLÜK</Subtitle3>
                        <Text3>
                            13 (onüç) maddeden ibaret işbu Sözleşme, Üye
                            tarafından her bir hükmü okunarak ve bütünüyle
                            anlaşılarak elektronik ortamda onaylanmak suretiyle,
                            onaylandığı an itibariyle yürürlüğe girmiştir.
                        </Text3>
                    </MarginWrapper>
                </StaticContentArea>
            ) : (
                <StaticContentArea>
                    <MarginWrapper>
                        <H3>MEMBERSHIP AGREEMENT</H3>
                    </MarginWrapper>
                    <MarginWrapper>
                        <Subtitle3>
                            ARTICLE 1 - PARTIES TO THE AGREEMENT
                        </Subtitle3>
                        <Text3>
                            TITLE : KEIKEI TEKSTIL VE GIYIM SANAYI TICARET
                            ANONIM ŞIRKETI
                        </Text3>
                        <Text3>
                            ADDRESS : Dolapdere Cd. Kucukbayir Sk. 119/A Sisli /
                            Istanbul
                        </Text3>
                        <Text3>
                            Hereinafter, it will be referred as `KEIKEI`.
                        </Text3>
                        <Text3>
                            The real person who becomes a member of the
                            www.keikei.com site in accordance with this
                            agreement and purchases the products and/or services
                            offered for sale on the site is the `Member`. The
                            Parties shall be referred to individually as the
                            `Party` and together as the `Parties`.
                        </Text3>
                        <Subtitle3>
                            ARTICLE 2 – SCOPE AND PURPOSE OF THE AGREEMENT
                        </Subtitle3>
                        <Text3>
                            This Membership Agreement has been concluded to
                            determine the conditions for the Member to benefit
                            from the services offered on the website
                            www.keikei.com and to determine the mutual
                            obligations of the parties. With the acceptance of
                            the Agreement by the Member, the Member declares and
                            undertakes that he/she has accepted all kinds of
                            statements regarding the products, services,
                            content, applications and members on the Site.
                        </Text3>
                        <Subtitle3>ARTICLE 3- DEFINITIONS</Subtitle3>
                        <Text3>
                            Site : Refers to the website www.keikei.com where
                            various services and content are offered. Member :
                            Refers to the real person who becomes a member of
                            the www.keikei.com site in accordance with this
                            agreement and purchases the products and/or services
                            offered for sale on the site. Agreement : Refers to
                            the membership agreement between the member and
                            KEIKEI, which regulates the use of the site and the
                            mutual obligations of the parties. My Account Tab :
                            Refers to the Member-specific page, which can be
                            accessed with the user name and password determined
                            by the member, where the member enters his/her
                            personal information in order to benefit from
                            various applications and services on the site.
                            Privacy and Cookie Policy: Developing and improving
                            the services offered to the members by increasing
                            the functionality and performance of the site,
                            personalizing the shopping experience of the Member,
                            in line with the content displayed by the Member on
                            the site and the information regarding his visits
                            for the next uses of the Member, according to his
                            preferences and tastes means the cookies used to
                            ensure the legal and commercial security of the site
                            and the Member and the text that includes the
                            management of these cookies. Personal Data Storage
                            and Destruction Policy: Refers to the texts that can
                            be accessed through the Site, in which the
                            processing times of the Member`s personal data are
                            determined by KEIKEI and the criteria and methods
                            for deletion, destruction or anonymization of
                            personal data whose processing time and / or purpose
                            of processing are eliminated.
                        </Text3>
                        <Subtitle3>
                            ARTICLE 4- RIGHTS AND OBLIGATIONS OF THE PARTIES
                        </Subtitle3>
                        <Subtitle3>
                            4.1. MEMBER`S RIGHTS AND OBLIGATIONS
                        </Subtitle3>
                        <Text3>
                            4.1.1. Taraflar arasındaki üyelik ilişkisi üye
                            tarafından üyelik bilgileri ile şifrenin girilmesi
                            ve işbu üyelik sözleşmesinin kabulüne ilişkin
                            kutucuğun işaretlenmesi akabinde satıcı tarafından
                            kayıt işleminin onaylanması ile kurulacaktır. Üye,
                            üyelik işlemlerinin gerçekleştirilmesi sırasında
                            bildirmiş olduğu bilgilerin doğru ve güncel
                            olduğunu, bilgilerin eksik veya yanlış olması
                            nedeniyle doğabilecek tüm zararlardan bizzat sorumlu
                            olduğunu kabul, beyan ve taahhüt eder.
                        </Text3>
                        <Text3>
                            4.1.1. The membership relationship between the
                            parties will be established after the member enters
                            the membership information and password and ticks
                            the box regarding the acceptance of this membership
                            agreement, and then the registration process is
                            approved by the seller. The member accepts, declares
                            and undertakes that the information he/she has given
                            during the membership process is correct and
                            up-to-date, and that he/she is personally
                            responsible for all damages that may arise due to
                            incomplete or incorrect information.
                        </Text3>
                        <Text3>
                            4.1.2. The Member has the right and authority to
                            withdraw his explicit consent for the processing
                            and/or transfer of his personal data, which is
                            processed and/or transferred based on his explicit
                            consent, that he has read and understood the
                            clarification text presented on the website by
                            KEİKEI with the establishment of this membership
                            agreement; 6698 p. He accepts, declares and
                            undertakes that he has been informed about the
                            rights granted to him by Article 11 of the Law and
                            how he can use these rights.
                        </Text3>
                        <Text3>
                            4.1.3. The password needed for the realization of
                            membership transactions will be determined by the
                            Member and the Member will be personally responsible
                            for the use, management, security and
                            confidentiality of all information, including the
                            user name and password that allows the use of his
                            personal profile. The member accepts, declares and
                            undertakes that he will not share his username and
                            password with third parties under any circumstances
                            and will not transfer his user account to third
                            parties.
                        </Text3>
                        <Text3>
                            4.1.4. The member states that the transactions
                            carried out with his/her user name and password are
                            carried out by him/her, the responsibility arising
                            from these transactions belongs to him/her, he/she
                            cannot claim any defense and/or objection that
                            he/she did not carry out the works and transactions
                            performed on his/her own account, and he/she shall
                            declare this def and accepts, declares and
                            undertakes that it cannot avoid fulfilling its
                            obligations based on objections.
                        </Text3>
                        <Text3>
                            4.1.5. The member accepts, declares and undertakes
                            that he will act in accordance with the provisions
                            of this Agreement and the current legislation while
                            benefiting from the services offered to him through
                            the site and in all transactions and correspondence
                            to be carried out on the site, and that he will not
                            use the site in an unlawful and unethical manner,
                            especially in the following cases.
                        </Text3>
                        <Text3>
                            4.1.5. Üye site üzerinden kendisine sunulan
                            hizmetlerden yararlanırken ve site üzerinde
                            gerçekleştireceği tüm işlemler ve yazışmalarda işbu
                            Sözleşme hükümlerine ve yürürlükteki mevzuata uygun
                            olarak hareket edeceğini, aşağıdaki haller başta
                            olmak üzere siteyi hukuka ve ahlaka aykırı bir
                            şekilde kullanmayacağını kabul, beyan ve taahhüt
                            eder.
                        </Text3>
                        <Text3>
                            • Using the Site for the purpose of creating,
                            controlling, updating or changing a database, record
                            or directory on behalf of any person,
                        </Text3>
                        <Text3>
                            • Using the entire or part of the Site for the
                            purpose of disrupting, altering or reverse
                            engineering
                        </Text3>
                        <Text3>
                            • Making transactions using false information or
                            another person`s information, creating unreal
                            Membership accounts by using false or misleading
                            personal data, including wrong or misleading
                            residence address, e-mail address, contact, payment
                            or account information, and these accounts are not
                            subject to the Membership Agreement or being used in
                            violation of the applicable legislation,
                            unauthorized use of another Member`s account, being
                            a party or participant in transactions by
                            impersonating another person or under a wrong name;
                        </Text3>
                        <Text3>
                            • Using for purposes such as posting comments on the
                            Site outside of the Site,or using for purposes other
                            than those used to manipulate systems on “Comment
                            and scoring section”
                        </Text3>
                        <Text3>
                            • Creating content in the comment section includes
                            the subject of which constitutes a crime, results in
                            or encourages the violation of the rules on the
                            website, applicable legislation or international
                            agreements, is illegal, threatening, contains
                            advertising and / or marketing content, is
                            offensive, contains insults and profanity,
                            humiliating sharing any vulgar, pornographic or
                            immoral, contrary to the generally accepted rules of
                            society, against personal rights, against
                            intellectual and industrial rights, creats unfair
                            competition on the Sitei
                        </Text3>
                        <Text3>
                            • Spreading viruses or any other harmful technology
                            to the Site, the Site`s database, or any content on
                            the Site.
                        </Text3>
                        <Text3>
                            • Engaging in activities that will create
                            unreasonable or disproportionately large downloads
                            on communications and technical systems determined
                            by the Site or damage technical operation, automatic
                            programs, robots, web crawlers, spiders, data mining
                            on the Site without the prior written consent of
                            KEIKEI. Using `screen scraping` software such as
                            (data mining) and data crawling and other methods,
                            including but not limited to, copying, publishing or
                            using all or part of any content on the Site without
                            permission.
                        </Text3>
                        <Text3>
                            4.1.6. The member accepts and undertakes that it has
                            taken all necessary precautions, including using the
                            necessary protective software and licensed products,
                            in order not to contain harmful codes including All
                            kinds of programs, viruses, software, unlicensed
                            products, trojan horses, etc. that may harm the
                            Site.
                        </Text3>
                        <Text3>
                            4.1.7. In case of explicit consent by the member
                            under the “Explicit Consent for Commercial
                            Communication/Commercial Communication Permission”;
                            the Member declares and accepts that he/she allows
                            that product and service promotions, advertisements,
                            campaigns, advantages, surveys and other customer
                            satisfaction practices to be presented to him/her by
                            KEIKEI within the scope of the practices in force
                            and/or to be put into effect.
                        </Text3>
                        <Text3>
                            4.1.8. In case the Member uses the Site in violation
                            of any article in the Agreement, KEIKEI has the
                            right to prevent the Member from using the Site
                            partially and/or completely in whole and/or a part
                            of it.
                        </Text3>
                        <Subtitle3>
                            4.2. RIGHTS AND OBLIGATIONS OF THE KEIKEI
                        </Subtitle3>
                        <Text3>
                            4.2.1 KEIKEI has the right to reject membership
                            applications or to impose additional terms and
                            conditions for membership applications, with its own
                            will and decision, without giving any reason.
                        </Text3>
                        <Text3>
                            4.2.2. KEIKEI always has the right to make changes
                            in all kinds of policies and contracts, including
                            the services offered on the site, this Membership
                            Agreement, the Personal Data Storage and Destruction
                            Policy on the Site, and the Cookie Policy, without
                            giving any reason and without notice, provided that
                            they do not contradict the provisions of the
                            legislation. The amendments to be made will be
                            binding for the Parties as of the date of
                            publication, the remaining provisions will remain in
                            effect and continue to have their provisions and
                            consequences.
                        </Text3>
                        <Text3>
                            4.2.3. KEIKEI and the members of the Company`s board
                            of directors, managers, employees and the persons
                            who prepared the information on this site are not
                            responsible for any direct or indirect material
                            and/or moral, negative and/or positive damages that
                            may arise due to entering the Site, using the Site
                            or the information, data and programs on the Site,
                            breach of contract, tortious act acts or other
                            reasons. As a result of breach of contract, tort,
                            negligence or other reasons; KEİKEİ does not accept
                            any responsibility for interruption of the
                            transaction, error, negligence, interruption.
                        </Text3>
                        <Text3>
                            4.2.4. KEIKEI reserves the right to remove messages,
                            comments and contents from access that against the
                            membership agreement, law and general moral rules,
                            threatening, false, misleading, provocative,
                            defamatory, invasive of privacy, obscene,
                            pornographic, abusive, discriminatory, illegal or
                            criminal, encourages any behavior that violates the
                            rights of any party or that may otherwise give rise
                            to legal liability or that may violate any law or
                            that is unacceptable to the KEIKEI at any time.
                        </Text3>
                        <Text3>
                            4.2.5. KEIKEI, has the right to collect some
                            information such as such as the name and Internet
                            Protocol (IP) address of the internet service
                            provider used by the users, the date and time the
                            site was accessed, the pages accessed while on the
                            site, and the internet address of the website that
                            provides direct connection to the site in order to
                            detect and solve problems that may occur in the
                            system related to the Site, to access the site for
                            the improvement and development of the website and
                            the system.
                        </Text3>
                        <Subtitle3>ARTICLE 5- PRIVACY</Subtitle3>
                        <Text3>
                            KEIKEI will be responsible for the processing,
                            security and protection of the personal data
                            provided by the Member through the Site in
                            accordance with the Law on the Protection of
                            Personal Data No. 6698 and secondary legislation. In
                            this context, KEIKEI accepts, declares and
                            undertakes that it will collect, process, use, store
                            and transfer the personal data provided by the
                            Member, limited to the purposes set forth in the
                            lighting text and in accordance with the Personal
                            Data Storage and Destruction Policy and Cookie
                            Policy on the Site. .
                        </Text3>
                        <Subtitle3>
                            ARTICLE 6- INTELLECTUAL PROPERTY RIGHTS
                        </Subtitle3>
                        <Text3>
                            Intellectual property rights of all audio, visual,
                            written materials and content on the Site, including
                            but not limited to the domain name, trademark, logo,
                            design, graphic, visual and pictures, belongs to
                            KEIKEI,memebers may not directly and/or indirectly
                            copy, reproduce, publish, distribute, transmit,
                            modify, and/or otherwise use the content of the
                            Site, in kind or otherwise, without KEIKEI ‘s
                            written consent. Otherwise, the Member shall be
                            entitled to all kinds of damages, including but not
                            limited to KEIKEI`s indemnities, court costs and
                            attorney`s fees, due to any damages incurred/to be
                            incurred by KEIKEI and any damages incurred by third
                            parties, including but not limited to the licensor
                            will be responsible for paying any damages to KEIKEI
                            immediately.
                        </Text3>
                        <Subtitle3>ARTICLE 7- FORCE MAJEURE</Subtitle3>
                        <Text3>
                            If events beyond the control of KEIKEI including
                            natural disaster, epidemic, strike, government, law
                            and law changes, uprising, embargo, riot,
                            occupation, war, mobilization, lockout, employement
                            actions or boycotts, cyber attack, communication
                            problems, infrastructure and internet failures,
                            system improvement or renovation works and
                            malfunctions that may occur for this reason, power
                            cut, fire, explosion, storm, flood, earthquake,
                            migration, or any other natural disaster, any
                            administrative and/or judicial decision or similar
                            actions that will prevent access to the Site prevent
                            or delay the fulfillment of KEIKEI`s obligations
                            arising from this membership agreement, KEIKEI will
                            not be held responsible for the fulfillment of the
                            obligations and this situation will not be
                            considered as a breach of the contract.
                        </Text3>
                        <Subtitle3>
                            ARTICLE 8- INTERPRETATION OF THE AGREEMENT
                        </Subtitle3>
                        <Text3>
                            The determination of any article, sub-article or
                            provision of this Agreement other than its essential
                            elements to be invalid or legally unenforceable will
                            not affect the applicability or validity of the
                            other articles, sub-articlex or provisions of this
                            Agreement. In such a case, any article, sub-article
                            or provisions of this Agreement that are found to be
                            invalid or legally unenforceable in all respects
                            will be interpreted and executed as if they were
                            removed from the text of the Agreement.
                        </Text3>
                        <Text3>
                            Personal Data Storage and Disposal Policy, Cookie
                            Policy and lighting Text will also be interpreted
                            with this Agreement as an integral part of this
                            Agreement.
                        </Text3>
                        <Subtitle3>
                            ARTICLE 9- CANCELLATION OF MEMBERSHIP AND
                            TERMINATION OF THE AGREEMENT
                        </Subtitle3>
                        <Text3>
                            Either party may terminate this agreement
                            unilaterally at any time.
                        </Text3>
                        <Text3>
                            KEIKEI reserves the right to partially or completely
                            freeze or cancel, unilaterally terminate this
                            contract and cancel the Member`s membership without
                            the need for any notification and without any
                            justification in the event that the Member acts in
                            breach of any or all of its obligations arising from
                            this contract.
                        </Text3>
                        <Subtitle3>
                            ARTICLE 10- MISCELLANEOUS PROVISIONS
                        </Subtitle3>
                        <Text3>
                            Evidence Agreement: The Member, without prejudice to
                            the MEMBER`s right to present other evidence in
                            disputes that may arise from this Membership
                            Agreement; Official books and commercial records of
                            KEIKEI and e-archive records kept in KEIKEI`s
                            database and servers, electronic information,
                            electronic correspondence and computer records shall
                            constitute binding and conclusive evidence and
                            accepts this article shall be in the nature of an
                            evidential contract within the meaning of Article
                            193 of the Code of Civil Procedure No. 6100.
                        </Text3>
                        <Text3>
                            Integrity and Severability of the Membership
                            Agreement: This Membership Agreement constitutes the
                            entire agreement between the Parties on the subject.
                            If any provision of this Membership Agreement is
                            determined by any competent court, arbitral tribunal
                            or administrative authority to be wholly or
                            partially invalid or unenforceable or unreasonable,
                            To the extent such invalidity, unenforceability or
                            unreasonability, this Membership Agreement shall be
                            deemed severable and the other provisions shall
                            remain in full force and effect.
                        </Text3>
                        <Text3>
                            Assignment of the Membership Agreement: The Member
                            shall not assign its rights or obligations under
                            this Membership Agreement, in whole or in part,
                            without the prior written consent of the KEIKEI.
                        </Text3>
                        <Text3>
                            Amendment and Waiver: Failure of one of the Parties
                            to enforce or exercise any right granted to him in
                            the Membership Agreement shall not mean that he
                            waives such right or will not prevent the further
                            exercise or enforcement of such right.
                        </Text3>
                        <Subtitle3>
                            ARTICLE 11- COMPETENT COURT AND APPLICABLE LAW,
                            RESOLUTION OF DISPUTES
                        </Subtitle3>
                        <Text3>
                            Turkish Law will be applied to the disputes arising
                            from this Membership agreement and Istanbul
                            (Çağlayan) Courts and Enforcement Offices will be
                            authorized to resolve any disputes that may arise
                            from the application of this agreement and its
                            annexes.
                        </Text3>
                        <Subtitle3>ARTICLE 12- NOTIFICATION</Subtitle3>
                        <Text3>
                            The address specified by KEIKEI in this contract and
                            the e-mail address notified by the Member to KEIKEI
                            will be accepted as the address of the parties
                            suitable for notification for any notification to be
                            made regarding the contract, and the notification
                            made to the specified addresses will result in a
                            valid notification duly made.
                        </Text3>
                        <Subtitle3>ARTICLE 13- EFFECTIVENESS</Subtitle3>
                        <Text3>
                            This Agreement, which consists of 13 (thirteen)
                            articles, has entered into force as of the moment of
                            its approval, by being approved by the Member in
                            electronic environment by reading and fully
                            understanding each of its provisions.
                        </Text3>
                    </MarginWrapper>
                </StaticContentArea>
            )}
        </AgreementModalWrapper>
    );
};

export default MembershipAgreement;
