const CargoIcon = (props) => (
    <svg width={22} height={21} xmlns="http://www.w3.org/2000/svg" {...props}>
        <g fill={`${props.fill || '#2e2e2e'}`} fillRule="evenodd">
            <path
                d="M18.26 4.22a1.818 1.818 0 0 0-1.053-.334h-3.064V1.575a1.59 1.59 0 0 0-.454-1.113A1.535 1.535 0 0 0 12.596 0H1.546A1.53 1.53 0 0 0 .455.462 1.59 1.59 0 0 0 0 1.575v15.897c0 .337.132.66.366.898.234.238.551.372.882.373H2.75a2.662 2.662 0 0 0 1.4 1.938 2.58 2.58 0 0 0 2.363 0 2.662 2.662 0 0 0 1.4-1.938h6.176a2.618 2.618 0 0 0 1.366 1.964c.741.39 1.623.39 2.364 0a2.618 2.618 0 0 0 1.366-1.964h1.571a1.24 1.24 0 0 0 .88-.375c.232-.238.364-.56.364-.896v-6.768c.001-.75-3.431-6.266-3.74-6.484zm-1.053.809a.719.719 0 0 1 .674.475c1.998 3.228 2.997 4.962 2.997 5.202v2.78h-6.735V5.029h3.064zM1.547 1.143h11.049a.43.43 0 0 1 .424.432v11.91H1.122V1.576a.43.43 0 0 1 .424-.432zm3.787 18.71c-.398 0-.78-.16-1.06-.447a1.541 1.541 0 0 1-.44-1.08c0-.404.158-.793.44-1.079a1.486 1.486 0 0 1 1.06-.447c.397 0 .779.16 1.06.447a1.542 1.542 0 0 1 0 2.159 1.488 1.488 0 0 1-1.06.448zm11.3 0a1.44 1.44 0 0 1-1.028-.433 1.494 1.494 0 0 1-.426-1.048c0-.392.153-.769.426-1.047a1.442 1.442 0 0 1 1.028-.434c.386 0 .756.156 1.03.434a1.495 1.495 0 0 1 0 2.093 1.444 1.444 0 0 1-1.027.436h-.003zm4.118-2.253h-1.653a2.61 2.61 0 0 0-1.402-1.618 2.535 2.535 0 0 0-2.12 0 2.61 2.61 0 0 0-1.403 1.618H7.857a2.653 2.653 0 0 0-1.423-1.699 2.575 2.575 0 0 0-2.193 0A2.652 2.652 0 0 0 2.817 17.6H1.246a.127.127 0 0 1-.124-.128v-2.843h13.021v-1.143h6.735v3.986a.128.128 0 0 1-.037.09.124.124 0 0 1-.089.038z"
                fillRule="nonzero"
            />
            <path d="M14.071 9.918h6.895v1.143h-6.895z" />
        </g>
    </svg>
);

export default CargoIcon;
