import { useWindowSize } from 'react-use';
import styled from 'styled-components';

import { Caption, H2 } from '@components/styles/Typography';

const AuthPageTitle = ({ title }) => {
    const { width } = useWindowSize();
    const isDesktop = width > 1024;

    if (isDesktop) {
        return (
            <TitleWrapper>
                <H2 uppercase>{title}</H2>
            </TitleWrapper>
        );
    }
    return (
        <TitleWrapper>
            <Caption uppercase color="bgGray50">
                {title}
            </Caption>
        </TitleWrapper>
    );
};
export default AuthPageTitle;

const TitleWrapper = styled.div`
    margin: 0 0 42px 0;

    @media (max-width: 1024px) {
        margin: 0 0 25px 0;
    }
`;
